.div-steps-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
}

.h2-title{
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    font-weight: bolder;
    font-size: 28px;
}

.no-margin{
    margin: 0;
}

.div-titles{
    display: flex;
    align-items: flex-start;
    justify-content: start;
}

.textarea-description, .input-title{
    width : 472px; 
    border-radius: 10px ;
    border-width: 0;
    resize: none;
    background-color: #E8E6E5;
}

.input-title{
    height:50px;
    padding-left: 20px;
}
.textarea-description{
    padding-left: 20px;
    padding-top: 20px;
    height: 180px;
}

.div-next{
    margin-top: 50px;
    display: flex;
    width: 100%;
    justify-content: end;
}
.div-next-previous{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.p-next{
    background-color: #000000;
    color: #fff;
    border-radius: 10px;
    width: 135px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    cursor: pointer;
}

.button-delete-produit{
    background-color: #000000;
    color: #fff;
    border: none;
    height: 40px;
    border-radius: 10px;
    cursor: pointer;


}

.p-previous{
    color: #1B1C3C;
    font-weight: 900;
    cursor: pointer;
}

.div-button{
    background-color: #E8E6E5 ;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    margin-right: 15px;
}

.actifType{
    background-color: #1B1C3C;
}

.divdiv-choix-type{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 472px;
}
.div-choix-type{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}


.div-type{
    display: flex;
    justify-content: start;
    flex-direction: column;
}
.div-titles{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.div-double-input{
    display: flex;
    align-items: center;
}

.input-produit{
    border-radius: 10px ;
    border-width: 0;
    resize: none;
    background-color: #E8E6E5;
    height:50px;
    padding-left: 20px;
    margin: 5px;
}

.div-ajoutProduit{
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;

}
.img-more{
    width: 24px;
    height: 24px;
}

.div-inputs-date{
    display: flex;
    align-items: center;
    width: 500px;
    justify-content: space-around;
}

.input-date{
    background-color: #E8E6E5 ;
    border : none;
    border-radius: 15px;
    width: 214px;
    height: 40px;
    color: #BEBEBE;
    padding: 5px;
}

.div-date-limite{
    display: flex;
    width: 500px;
    flex-direction: column;
    justify-content: flex-start;
}

.div-button2{
    background-color: #0d9c0d ;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 15px;
}

.actifType2{
    background-color: #940c0c;
}
.div-pharmacie-participantes, .div-statut{
    width: 530px;
}
.div-pharmacieParticipantes{
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.input-nom-pharmacie{
    background-color: #E8E6E5 ;
    border : none;
    border-radius: 15px;
    height: 34px;
    width: 214px;
    padding-left: 15px;
    margin-left: 10px;
}

.statut-toggle{
    cursor: pointer;
    display: flex;
    align-items: center;
}

.p-status{
    color: #000000;
    font-size: 18px;
    font-weight: 900;
}