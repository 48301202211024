@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.forget-password {
    margin: 0;
    position: relative;
    right: 0;
    font-family: 'Montserrat', sans-serif;
    text-decoration: underline;
    padding-bottom: 35px;
    font-size: 14px;
    text-align: right;
    cursor: pointer;
}

form{
    width: 20vw;
    max-width: 300px;
}

.container-connexion-field{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.container-label-input{
    display: flex;
    flex-direction: column;
}

.label-input{
    font-size: 18px;
    padding-bottom: 2px;
    font-family: 'Montserrat', sans-serif;
}

.input-first{
    margin-bottom: 20px;
    background-color: #E8E6E5;
    border: none;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
}

.input{
    background-color: #E8E6E5;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
}

.submit-btn{
    height: 50px;
    width: 190px;
    background-color: #1B1C3C;
    color: white;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
}

.container-btn{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.text-inscription{
    font-size: 16px;
    text-decoration: none;
    color: #1B1C3C;
    font-weight: 700;
    padding-top: 10px;
    cursor: pointer;
}