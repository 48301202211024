@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-sidebar{
    height: 100vh;
    width: 20vw;
    max-width: 340px;
    min-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid #D9D9D9;
}

.prescription-nature-logo{
    height: 78px;
    width: 220px;
    margin: 0;
    padding: 0;
    margin-top: 30px;
    margin-bottom: 60px;
}

.label-sidebar{
    font-size: 16px;
    font-family: 'Montserrat', 'sans-serif';
    width: 100%;
    padding-left: 100px;
    margin-bottom: 30px;
    cursor: pointer;
}

.current{
    color: #7CB4A8;
    font-weight: 700;
}

.footer-sidebar{
    display: flex;
    width: 100%;
    margin-left: 95px;
    margin-top: auto;
    padding-bottom: 10px;
}

.logout-sidebar{
    font-size: 16px;
    font-family: 'Montserrat', 'sans-serif';
    cursor: pointer;
    font-weight: 700;
    margin-top: 2px;
    margin-left: 10px;
}

.logout-img-sidebar{
    height: 24px;
    width: 24px;
}