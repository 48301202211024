@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-header{
    width: 100%;
    display: flex;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: space-between;
}

.title-header{
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    text-transform: uppercase;
    font-weight: 350;
    margin: 0;
    padding-left: 55px;
}

.title-header strong {
    font-weight: 750;
}

.container-header-information{
    display: flex;
    align-items: center;      
}

.container-header-account{
    width: 100%;
}

.container-header-account{
    display: flex;
}

.container-header-text-account{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 55px;
    padding-left: 5px;
}

.text-account{
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.icon-header{
    height: 50px;
}

.bold{
    font-weight: 700;
}

.svg-header{
    height: 58px;
}