@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

form{
    width: 20vw;
    max-width: 300px;
}

.container-connexion-field{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.container-label-input{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.container-label-input-first{
    display: flex;
    flex-direction: column;

}

.label-input{
    font-size: 18px;
    padding-bottom: 2px;
    font-family: 'Montserrat', sans-serif;
}

.input-first{
    margin-bottom: 20px;
    background-color: #E8E6E5;
    border: none;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
}

.input{
    background-color: #E8E6E5;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
}

.submit-btn-inscription{
    height: 50px;
    width: 190px;
    background-color: #1B1C3C;
    color: white;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 35px;
}

.container-btn{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.text-inscription{
    font-size: 16px;
    text-decoration: none;
    color: #1B1C3C;
    font-weight: 700;
    padding-top: 10px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}

.icon-information{
    height: 18px;
    text-align: center;
    margin-left: 5px;
}

.container-inputPass-icon{
    display: flex;
    align-items: center;
}
