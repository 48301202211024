
.tableauPDV-container{
    width: 712px;
}
.tableau_pdv_header{
    display: flex;
    justify-content: space-around;
}

.tableau_pdv_contain{
    display: flex;
    justify-content: space-around;
}
.tableau_pdv_product {
    overflow-y: auto;
    max-height: 310px;
}
.tableau_pdv_header::after {
    content: '';
    width: 18px; 
    flex: none; 
}

.tableau_pdv_contain p, .tableau_pdv_header p {
    flex: 1;
    margin: 1px;
    margin-right: 2px;
    padding-top:  10px;
    padding-bottom:  10px;
}

.tableau_pdv_header p{
    background-color: #1B1C3C ;
    color: #fff;   
}

.tableau_pdv_contain p {
    background-color: #E8E6E5 ;
    color: #BEBEBE;
}

.tableau_pdv_contain input {
    background-color: #E8E6E5 ;
    color: #BEBEBE;
    border: solid 1px white;
    text-align: center;
    color: black;
    width: 130px;
}

.corner_topRight{
    border-top-right-radius: 15px;
}

.corner_topLeft{
    border-top-left-radius: 15px;
}
.corner_bottomRight{
    border-bottom-right-radius: 15px;
}

.corner_bottomLeft{
    border-bottom-left-radius: 15px;
}

.totaux_tableauPDV{
    display: flex;
    justify-content: space-around;
}

.totaux-p{
    width: 150px;
    display: flex;
    justify-content: space-evenly;
}

.p-vert{
    color: #7CB4A8;
}

.container-update-sell {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 1%;
    
}

.input-group {
    display: flex;
    align-items: center;
    gap: 10px;
}

.suggestions-list {
    max-height: 74px; 
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 24%; 
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}

.button-group {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}

.button-update-sell {
    margin-left: 10px;
    background-color: #1b1c3c;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    outline: none;
    padding: 10px;
}

.input-group input {
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px; 
    border: 1px solid #ccc;
    padding: 5px 10px; 
}