.listPDV-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    margin-left: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 10px; /* Ajouté pour l'espace en bas */
}

.listPDV-titre {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    text-align: center; /* Centré le titre */
    padding: 10px; /* Ajouté pour un peu d'espacement */
    margin: 0; /* Enlève les marges par défaut */
}

.listPDV-listPDF {
    max-height: 150px; /* Hauteur maximale avant que le défilement ne se déclenche */
    overflow-y: auto; /* Active la barre de défilement si nécessaire */
    flex-direction: column;
    justify-content: flex-start; /* Alignez les éléments au début du conteneur */
    margin: 10px; /* Espace autour des liens PDF */
    padding: 10px 0; /* Espace en haut et en bas à l'intérieur du conteneur */
}


.listPDV-linkpdf {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #474748;
    text-decoration: none; /* Enlève le soulignement par défaut */
    margin: 5px 0; /* Ajouté pour un peu d'espacement entre les liens */
    display: block; /* Force le lien à être sur sa propre ligne */
    cursor: pointer;
}

.listPDV-acceptornot {
    border-top: solid 2px #E8e8e8;
    display: flex;
    justify-content: space-around;
}

.listPDV-divCheck {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Ajouté pour l'espacement entre l'image et le texte */
}

.listPDV-img-check {
    width: 28px;
    height: 28px;
}

.listPDV-check {
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    font-weight: bold;
}
