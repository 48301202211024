.text-input{
    margin: 0px;
    margin: 5px;
}

.container-viewInscription{
padding: 20px;
}

.margin{
    margin: 0;
}

.btn-find{
    height: 35px;
    width: 75px;
    border-radius: 5px;
    margin-left: 10px;
    font-family: sans-serif;
    font-size: 15px;
}
/* Conteneur pour centrer et espacer les tableaux */
.table-container {
    max-width: 800px; /* Limiter la largeur maximale du conteneur */
    margin: 0 auto; /* Centrer le conteneur horizontalement */
    padding: 20px; /* Ajouter de l'espace autour du conteneur */
    padding-left: 0px;
}

/* Styles pour les tableaux */
.challenge-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

/* En-têtes des tableaux */
.challenge-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

/* Contenu des cellules */
.challenge-table td {
    padding: 12px 15px;
    font-size: 14px;
    color: #555;
    border-bottom: 1px solid #e0e0e0;
}

/* Ligne de tableau alternée */
.challenge-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Effet de survol sur les lignes */
.challenge-table tr:hover {
    background-color: #e9e9e9;
    cursor: pointer;
}

/* Boutons d'action dans les tableaux */
.challenge-table button {
    background-color: #4CAF50;
    color: #ffffff;
    border: none;
    padding: 6px 10px;
    border-radius: 4px;
    font-size: 13px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.challenge-table button:hover {
    background-color: #45a049;
}
