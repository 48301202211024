body {
    margin: 0;
    padding: 0;
}

.container-homePage {
    display: flex;
}

.container-all-data {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Personel  */

.title-tab {
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    font-weight: bolder;
    margin-left: 20px;
}

.img-trash{
    width: 20px;
}

.input-find {
    width: 30ch;
    height: 4ch;
    background-color: #E8E6E5;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s, border-color 0.3s;
    outline: none;
    padding-left: 10px;
    margin-left: 20px;
}
.input-find::placeholder{
    font-family: 'Montserrat', sans-serif;
}

.input-find:focus{
    background-color: #fff;
    border-color: #7CB4A8;
}

table {
    margin-left: 20px;
    width: 80%;
    border-collapse: separate; /* Assurez-vous que les bordures sont séparées */
    border-spacing: 0 10px; /* Ajoutez de l'espace vertical de 10 pixels entre les lignes */    
}


th,td{
    font-family: 'Montserrat', sans-serif;
}

.tr-clients {
    border-radius: 10px; /* Ajoutez un arrondi de 10 pixels à chaque ligne */
    overflow: hidden; /* Assurez-vous que le contenu ne dépasse pas les bords arrondis */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Ombre avec décalage vertical de 2px et flou de 4px */
}

td{
    padding: 10px;
    text-align: center;
}

.td-delete {
    display: flex;
    color: #1B1C3C;
    font-weight: bolder;
    cursor: pointer;
}

.pagination{
    width: 70%;
    display: flex;
    justify-content: center;
}

.button-pagination{
    font-family: 'Montserrat', sans-serif;
    background-color: #1B1C3C;
    font-weight: bold;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.button-pagination:hover{
    background-color: #E8E6E5;
    color: #1B1C3C;
    border-color: #1B1C3C;
}

.div-bottomTabButtons{
    display: flex;
}



/* Nouveau depuis inscription */
.img-check,.img-cancel{
    width: 25px;
}
.img-check:hover,.img-cancel:hover{
    border-radius: 30px;
    background-color: #bebebe;
}
.button-checkCancel{
    background-color: #fff;
    border-width: 0;
    cursor: pointer;
}