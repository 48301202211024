/* .globalDocument-container {
    display: flex;
    flex-direction: column;
    width: 265px;
    justify-content: center;
    margin-left: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
   
} */
.globalDocument-container h3 {
    max-height: 150px; 
    overflow-y: auto; 
    flex-direction: column;
    justify-content: flex-start; 
    margin: 10px; 
    padding: 10px 0; 
}


.button-update-doc {
    margin-left: 10px;
    background-color: #1b1c3c;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    outline: none;
    padding: 10px;
}