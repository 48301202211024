.div-popUpPDV {
    position: fixed;
    top: 64%;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    /* width: 1000px; */
    text-align: center;
    z-index: 999; 
    max-height: 600px; 
}
.div-data{
    display: flex;
    margin-left: 5px ;
    margin-right: 5px ;
}

.div-datas{
    position: absolute;
    margin-top: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #fff;
}

.p-data{
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    margin: 2px;
}

.premiere-ligne{
    display: flex;
    justify-content: space-between;
}

.nomPharmacie{
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    
}

.popUpPDV_xToClose{
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    font-weight: bolder;
    font-size: 26px;
    cursor: pointer;
}

.challengeTrim-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    justify-content: center;
    margin-left: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    padding-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
   
}
