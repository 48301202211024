.div-container{
    width: 250px;
    margin: 10px;
    border-radius: 20px;
    padding : 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.p-title {
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    font-weight: bolder;
}

.p-number{
    font-family: 'Montserrat', sans-serif;
    color: #7CB4A8;
    font-weight: bolder;
    font-size: 30px;
    margin: 0;
    margin-left: 10px;
}


.div-detail{
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer;
}