.div-button{
    margin-right: 20px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #BEBEBE;
    color: #fff;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    cursor: pointer;
}

.actif{
    background-color: #1B1C3C;
}


.div-3buttons{
    margin-left: 50px;
    margin-top: 30px;
    display: flex;
    width: 90%;
    justify-content: space-between;
}

.div-2buttons{
    display: flex;
}

.actifType{
    background-color: #1B1C3C;
}

.contain-everything{
    margin-left: 50px;
}

.input-date{
    color: black;
}