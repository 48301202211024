.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 998;
}

.div-popUpGraph {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 600px;
    text-align: center;
    z-index: 999;
}

.title-namePharmacie {
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    font-weight: bolder;
}

.button-range {
    font-family: 'Montserrat', sans-serif;
    background-color: #BEBEBE;
    color: #ffffff;
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}



.active {
    background-color: #1B1C3C;
}

.div-close {
    width: 96%;
    border-color: #1B1C3C;
    border-width: 1px;
    display: flex;
    justify-content: flex-end;
}

.button-close {
    font-family: 'Montserrat', sans-serif;
    background-color: #fff;
    border: 0;
    font-weight: bold;
    font-size: larger;
    cursor: pointer;
    transition: border 0.3s;
}

.button-close:hover {
    background-color: #BEBEBE;
    color: #fff;
    border-radius: 2px;
}

.div-choiceDiv{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.div-choiceDiv>div{
    display: flex;
    flex-direction: column;
    align-items: center;
}